import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import EcoIcon from '@material-ui/icons/Eco';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import './album.css';

function CreativeCommons() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'CC0 '}
      <Link color="inherit" href="http://www.danbergh.se/">
        Dan Bergh
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  }
}));

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const customCards = [
  {
    "id": 1,
    "Title": "Spotify",
    "Image": require("./spotify.jpg"),
    "Heading": "Spotify",
    "Text": "Official releases of the Shizukana catalogue.",
    "Link": "Browse",
    "Href": "https://open.spotify.com/playlist/6VHULs7hCMGzh5bU9S89Kt?si=jkIaMfYuTVa5qIk-l4J1iQ"
  },
  {
   "id": 2,
   "Title": "Bandcamp",
   "Image": require("./bandcamp.jpg"),
   "Heading": "Bandcamp",
   "Text": "All releases of the Shizukana catalogue.",
   "Link": "Browse",
   "Href": "https://danbergh.bandcamp.com/"
  },
  {
   "id": 3,
   "Title": "SoundCloud",
   "Image": require("./soundcloud.png"),
   "Heading": "SoundCloud",
   "Text": "All releases of the Shizukana catalogue.",
   "Link": "Browse",
   "Href": "https://soundcloud.com/shizukana"
  },
  {
    "id": 4,
    "Title": "Last.fm",
    "Image": require("./lastfm.png"),
    "Heading": "Last.fm",
    "Text": "My page and listening trends.",
    "Link": "Visit",
    "Href": "https://www.last.fm/music/Dan+Bergh"
   },
   {
    "id": 5,
    "Title": "YouTube",
    "Image": require("./youtube.png"),
    "Heading": "YouTube",
    "Text": "Music and motion pictures published by Shizukana Entertainment.",
    "Link": "Browse",
    "Href": "https://www.youtube.com/user/ShizukanaMedia/videos"
   },
   {
    "id": 6,
    "Title": "LinkedIn",
    "Image": require("./linkedin.png"),
    "Heading": "LinkedIn",
    "Text": "Personal resume, etc.",
    "Link": "Connect",
    "Href": "https://www.linkedin.com/in/dan-bergh-95384853/"
   },
   {
    "id": 7,
    "Title": "Twitter",
    "Image": require("./twitter.png"),
    "Heading": "Twitter",
    "Text": "A good way of announcing new releases.",
    "Link": "Follow",
    "Href": "https://twitter.com/Burrpapp"
   },
   {
    "id": 8,
    "Title": "GitHub",
    "Image": require("./github.jpg"),
    "Heading": "GitHub",
    "Text": "Well, I should get more active again.",
    "Link": "Browse",
    "Href": "https://github.com/Burrpapp"
   },
   {
    "id": 9,
    "Title": "GitLab",
    "Image": require("./gitlab.png"),
    "Heading": "GitLab",
    "Text": "One of my favourite CI/CD tools out there.",
    "Link": "Browse",
    "Href": "https://gitlab.com/Burrpapp"
   },
   {
    "id": 10,
    "Title": "DeviantArt",
    "Image": require("./deviantart.png"),
    "Heading": "DeviantArt",
    "Text": "Visual creations of mine.",
    "Link": "Browse",
    "Href": "https://www.deviantart.com/burrpapp"
   },
   {
    "id": 11,
    "Title": "Itch.io",
    "Image": require("./itch-io.png"),
    "Heading": "Itch.io",
    "Text": "I'm an occasional game developer.",
    "Link": "Browse",
    "Href": "https://burrpapp.itch.io/"
   },
   {
    "id": 12,
    "Title": "Podcast",
    "Image": require("./minneskort.png"),
    "Heading": "Podcast",
    "Text": "I host a podcast in Swedish about video games.",
    "Link": "Listen",
    "Href": "https://poddtoppen.se/podcast/1454815380/minneskort"
   }];

const randomCards = shuffle(customCards);  

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <EcoIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
            Dan Bergh
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className="bg">
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Dan Bergh
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" paragraph>
                Software development, music production, motion pictures, photography and drawing.
                </Typography>
                <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                    <Grid item>
                    <Button variant="contained" color="primary">
                        <Link color="inherit" href="mailto:hello@danbergh.se">EMAIL</Link>
                    </Button>
                    </Grid>
                </Grid>
                </div>
            
          </Container>
        </div>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {randomCards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.Image}
                    title={card.Title}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.Heading}
                    </Typography>
                    <Typography>
                      {card.Text}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary" href={card.Href}>
                      {card.Link}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Dan Bergh
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Site created with Material-UI.
        </Typography>
        <CreativeCommons />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}